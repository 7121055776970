var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('create-update-wrapper',{attrs:{"is-create":_vm.isCreate,"client-id":_vm.zoneObject.zone.clientId,"show-buttons":_vm.showEditCard,"response":_vm.response,"is-loading":_vm.isLoading},on:{"loaded":_vm.onCreateUpdateComponentLoaded,"clientSelected":_vm.onClientSelected,"submit":_vm.onSubmit,"reset":_vm.onReset},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.isLoadingZone)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('v-card',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('createUpdate.zoneId'),"error-messages":_vm.validationErrors (
                'zoneObject.zone.userDefinedId',
                {required: 'createUpdate.formError.required.userDefinedId'}),"disabled":!_vm.isCreate},on:{"blur":function($event){_vm.$v.zoneObject.zone.userDefinedId.$touch ()}},model:{value:(_vm.zoneObject.zone.userDefinedId),callback:function ($$v) {_vm.$set(_vm.zoneObject.zone, "userDefinedId", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"zoneObject.zone.userDefinedId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('createUpdate.domainName'),"error-messages":_vm.validationErrors (
                'zoneObject.zone.domainName',
                {
                  required: 'createUpdate.formError.required.domainName',
                  idnDomain: 'general.invalid.asciiDomain'
                }),"disabled":!_vm.isCreate},on:{"blur":function($event){_vm.$v.zoneObject.zone.domainName.$touch ()}},model:{value:(_vm.zoneObject.zone.domainName),callback:function ($$v) {_vm.$set(_vm.zoneObject.zone, "domainName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"zoneObject.zone.domainName"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{attrs:{"multiple":"","items":_vm.notifications,"clearable":true,"label":_vm.$t('createUpdate.notifications')},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',{key:data.item,staticClass:"my-1",attrs:{"close":"","outlined":"","small":"","color":"black"},on:{"update:active":function($event){data.parent.selectItem (data.item)}}},[_c('span',{staticClass:"chipContent",attrs:{"title":data.item}},[_vm._v(" "+_vm._s(data.item)+" ")])])]}}]),model:{value:(_vm.zoneObject.zone.notifications),callback:function ($$v) {_vm.$set(_vm.zoneObject.zone, "notifications", $$v)},expression:"zoneObject.zone.notifications"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('name-server-select',{attrs:{"error-messages":_vm.validationErrors (
                'zoneObject.zone.nameServers',
                {notEmpty: 'createUpdate.formError.required.nameServers'}),"label":_vm.$t('createUpdate.nameServer')},on:{"blur":function($event){_vm.$v.zoneObject.zone.nameServers.$touch ()}},model:{value:(_vm.zoneObject.zone.nameServers),callback:function ($$v) {_vm.$set(_vm.zoneObject.zone, "nameServers", $$v)},expression:"zoneObject.zone.nameServers"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('provider-chain',{ref:"providerchainComponent",attrs:{"edit":_vm.zoneObject.editProviderChain},on:{"edit":function($event){_vm.zoneObject.editProviderChain = $event}},model:{value:(_vm.chainModel),callback:function ($$v) {_vm.chainModel=$$v},expression:"chainModel"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('radio-group',{attrs:{"option-values":[_vm.zoneTypes.LITERAL, _vm.zoneTypes.SOURCE],"option-labels":[_vm.$t('createUpdate.literal'), _vm.$t('createUpdate.source')]},model:{value:(_vm.zoneObject.type),callback:function ($$v) {_vm.$set(_vm.zoneObject, "type", $$v)},expression:"zoneObject.type"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-slide-y-transition',{attrs:{"mode":"out-in"}},[(_vm.zoneObject.type === 'literal')?[_c('v-textarea',{attrs:{"label":_vm.$t('createUpdate.resourceRecords'),"error-messages":_vm.validationErrors (
                    'zoneObject.zone.resourceRecords',
                    {isLiteral: 'createUpdate.formError.required.resourceRecords'}
                  )},on:{"blur":function($event){_vm.$v.zoneObject.zone.resourceRecords.$touch ()}},model:{value:(_vm.resourceRecords),callback:function ($$v) {_vm.resourceRecords=$$v},expression:"resourceRecords"}})]:[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('transfer-server-hint',{attrs:{"selected-name-servers":_vm.zoneObject.zone.nameServers}})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"label":_vm.$t('createUpdate.address'),"error-messages":_vm.validationErrors (
                        'zoneObject.zone.sourceData.address',
                        {
                          ip: 'general.invalid.ip',
                          isSource: 'createUpdate.formError.required.srcAddress'
                        })},on:{"blur":function($event){_vm.$v.zoneObject.zone.sourceData.address.$touch ()}},model:{value:(_vm.zoneObject.zone.sourceData.address),callback:function ($$v) {_vm.$set(_vm.zoneObject.zone.sourceData, "address", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"zoneObject.zone.sourceData.address"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-switch',{attrs:{"hide-details":"","label":_vm.$t ('createUpdate.editTsig')},model:{value:(_vm.zoneObject.showTsig),callback:function ($$v) {_vm.$set(_vm.zoneObject, "showTsig", $$v)},expression:"zoneObject.showTsig"}})],1),_c('v-slide-y-transition',{attrs:{"mode":"out-in"}},[(_vm.zoneObject.showTsig)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"items":_vm.tsigAlgs,"label":_vm.$t('createUpdate.tsigAlg')},model:{value:(_vm.zoneObject.zone.sourceData.tsigAlg),callback:function ($$v) {_vm.$set(_vm.zoneObject.zone.sourceData, "tsigAlg", $$v)},expression:"zoneObject.zone.sourceData.tsigAlg"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('createUpdate.tsigName'),"error-messages":_vm.validationErrors (
                              'zoneObject.zone.sourceData.tsigName',
                              {isSource: 'createUpdate.formError.required.tsigName'}
                            )},on:{"blur":function($event){_vm.$v.zoneObject.zone.sourceData.tsigName.$touch ()}},model:{value:(_vm.zoneObject.zone.sourceData.tsigName),callback:function ($$v) {_vm.$set(_vm.zoneObject.zone.sourceData, "tsigName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"zoneObject.zone.sourceData.tsigName"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('createUpdate.tsigSecret'),"error-messages":_vm.validationErrors (
                              'zoneObject.zone.sourceData.tsigSecret',
                              {
                                base64: 'general.invalid.base64',
                                isSource: 'createUpdate.formError.required.tsigSecret'
                              })},on:{"blur":function($event){_vm.$v.zoneObject.zone.sourceData.tsigSecret.$touch ()}},model:{value:(_vm.zoneObject.zone.sourceData.tsigSecret),callback:function ($$v) {_vm.$set(_vm.zoneObject.zone.sourceData, "tsigSecret", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"zoneObject.zone.sourceData.tsigSecret"}})],1)],1)],1):_vm._e()],1)],1)]],2)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-switch',{attrs:{"label":_vm.$t('createUpdate.editDnsSec')},model:{value:(_vm.zoneObject.zone.dnssecEnabled),callback:function ($$v) {_vm.$set(_vm.zoneObject.zone, "dnssecEnabled", $$v)},expression:"zoneObject.zone.dnssecEnabled"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-switch',{attrs:{"label":_vm.$t('createUpdate.editAutoLink')},model:{value:(_vm.zoneObject.zone.autoDomainLinkage),callback:function ($$v) {_vm.$set(_vm.zoneObject.zone, "autoDomainLinkage", $$v)},expression:"zoneObject.zone.autoDomainLinkage"}})],1)],1)],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }