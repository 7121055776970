import store from '@/app/store'

export default {
  data () {
    return {
      unsavedChanges: false
    }
  },

  methods: {
    setUnsavedChanges (data) {
      this.unsavedChanges = data
    }
  },

  beforeRouteLeave (to, from, next) {
    if (this.unsavedChanges) {
      store.commit ('create/setUnsavedChangesCB', (navigate) => {
        if (navigate) {
          next ()
        } else {
          next (false)
        }
        store.commit ('create/setUnsavedChangesDialog', false)
        store.commit ('create/setUnsavedChangesCB', null)
      })
      store.commit ('create/setUnsavedChangesDialog', true)
    } else {
      next ()
      this.unsavedChanges = false
    }
  },

  created () {
    window.onbeforeunload = (e) => {
      if (this.unsavedChanges) {
        return this.$t ('general.unsavedChangesDesc')
      }
    }
  }
}
