
  import c0 from "./ProviderChain.en.yaml"
import c1 from "./ProviderChain.de.yaml"
  export default function (Component) {
    // Component.options.name (if defined) can be used to load component
    // translations by default
    Component.options.__i18n = Component.options.__i18n || []
    Component.options.__i18n.push ('{"en":' + JSON.stringify(c0) + '}')
Component.options.__i18n.push ('{"de":' + JSON.stringify(c1) + '}')
  }
  