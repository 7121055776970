<template>
  <v-tooltip
    left
    :disabled="!tooltip">
    <template #activator="{ on }">
      <v-btn
        :type="type"
        :fixed="hasVerticalScrollbar"
        :loading="loading"
        :color="color"
        :class="hasVerticalScrollbar && !fixed
          ? [
            'fb', `fb-bottom-${bottomOffset}`,
            `fb-${isDrawerVisible ? '' : 'no-'}drawer-${leftOffset}`
          ] : []"
        v-on="on"
        @click="$emit ('click')">
        <slot/>
      </v-btn>
    </template>
    <span v-text="tooltip"/>
  </v-tooltip>
</template>

<script>
  import {mapGetters} from 'vuex'

  const DefaultOffset = 1

  /**
   * Check whether the specified offset is in the valid range.
   * @param {Number} offset     the offset to check
   * @return {Boolean}          true if offset is in [0, 3], else false
   */
  function offsetValidator (offset) {
    return offset >= 0 && offset <= 3
  }

  export default {
    name: 'FloatingButton',

    props: {
      type: {
        type: String,
        default: 'submit'
      },
      fixed: {
        type: Boolean
      },
      tooltip: {
        // the button tooltip
        type: String,
        default: undefined
      },
      lOffset: {
        // the left offset of the button (relative to the navigation drawer);
        // must be number between 0 and 3 (overrides offset)
        type: Number,
        default: undefined,
        validator: offsetValidator
      },
      bOffset: {
        // the bottom offset of the button (relative to the footer);
        // must be number between 0 and 3 (overrides offset)
        type: Number,
        default: undefined,
        validator: offsetValidator
      },
      offset: {
        // shortcut for setting l-offset and b-offset to the same value
        type: Number,
        default: undefined,
        validator: offsetValidator
      },
      loading: {
        // show loading icon animation
        type: Boolean
      },
      color: {
        // the button color
        type: String,
        default: undefined
      }
    },

    computed: {
      ...mapGetters ('layout', [
        'isDrawerVisible',
        'hasVerticalScrollbar'
      ]),

      leftOffset () {
        return this.lOffset || this.offset || DefaultOffset
      },

      bottomOffset () {
        return this.bOffset || this.offset || DefaultOffset
      }
    }
  }
</script>

<style lang="scss" scoped>
$drawerWidth: 300px;
$offset: 6px;
$offsetBottom: 16px;

.fb {
  z-index: 4;
}

@for $i from 0 through 3 {
  .fb-bottom-#{$i} {
    bottom: $offsetBottom + $i * $offset + 30;
  }

  .fb-drawer-#{$i} {
    left: $drawerWidth + $i * $offset;
  }

  .fb-no-drawer-#{$i} {
    left: $i * $offset;
  }
}
</style>
