<messages>["./RegistryObjectCreateUpdate"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <v-card :class="{'elevation-0': !showCardBorder}">
    <v-card-title v-if="!hideTitle" primary-title>
      <div
        v-t="'response.title'"
        class="text-h5 mr-1"/>
    </v-card-title>
    <v-card-text>
      <v-select
        v-if="provideSelect"
        v-model="selectedResponseIndex"
        :items="responseItems"
        :label="$t ('response.' + selectType )"
        :readonly="!multipleResults"
        :append-icon="multipleResults ? 'arrow_drop_down' : ''"/>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-alert
            type="error"
            v-on="on">
            {{ title }}
          </v-alert>
        </template>
        <span>{{ title }}</span>
      </v-tooltip>
      <div v-if="transactionIds && transactionIds !== ''">
        <v-subheader>{{ $tc ('response.transactionIds', transactionIds.length) }}</v-subheader>
        <div v-for="id in transactionIds" :key="id">
          {{ id }}
        </div>
      </div>
      <div
        v-if="selectedResponse.fieldErrors &&
          Object.keys (selectedResponse.fieldErrors).length > 0">
        <v-subheader v-t="'response.fieldErrors'"/>
        <v-list
          v-for="(error, key) in selectedResponse.fieldErrors"
          :key="key"
          two-line
          subheader>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-list-item v-on="on">
                <v-list-item-avatar>
                  <v-icon color="error">
                    warning
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ key }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ errorDescription (error) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span>{{ key }}: {{ errorDescription (error) }}</span>
          </v-tooltip>
        </v-list>
      </div>
      <div v-if="selectedResponse.serverErrors && selectedResponse.serverErrors.length">
        <v-subheader v-t="'response.serverErrors'"/>
        <v-list
          v-for="(error, idx) in selectedResponse.serverErrors"
          :key="idx"
          subheader>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-list-item v-on="on">
                <v-list-item-avatar>
                  <v-icon color="error">
                    warning
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ error }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span>{{ error }}</span>
          </v-tooltip>
        </v-list>
      </div>
    </v-card-text>
  </v-card>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  /**
   * @typedef {Object} OrderResponse
   * @property {String} resultCode - the response result code
   * @property {String} message - the response message
   * @property {Array.<Object>} fieldErrors - the field errors
   * @property {Array.<String>} serverErrors - the server errors
   * @property {String} identifier - the response identifier
   */

  export default {
    name: 'OrderProcessingResponse',

    props: {
      /**
       * @type {Array.<OrderResponse>}
       */
      response: {
        type: Array,
        required: true,
        validator (v) {
          return v.every (r =>
            r.hasOwnProperty ('message') && r.hasOwnProperty ('resultCode'))
        }
      },
      provideSelect: {
        type: Boolean,
        default: false
      },
      selectType: {
        type: String,
        default: undefined
      },
      showCardBorder: {
        type: Boolean,
        default: true
      },
      hideTitle: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        selectedResponseIndex: 0
      }
    },

    computed: {
      multipleResults () {
        return this.responseItems.length > 1
      },

      transactionIds () {
        return this.response.map ((error) => {
          return error.transactionId
        })
      },

      responseItems () {
        return this.response
          .map ((r, i) => ({
            text: r.identifier,
            value: i
          }))
      },

      /**
       *  @return {OrderResponse}
       */
      selectedResponse () {
        return this.response[this.selectedResponseIndex]
      },

      title () {
        let title

        if (this.selectedResponse) {
          const message = this.selectedResponse.message
          const code = this.selectedResponse.resultCode
          title = `${message} (${code})`
        }

        return title
      }
    },

    watch: {
      response () {
        this.selectedResponseIndex = 0
      }
    },

    methods: {
      errorDescription (error) {
        return error.message + (error.code ? ` (${error.code})` : '')
      }
    }
  }
</script>
