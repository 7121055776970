<messages>["../Zone"]</messages>

<template>
  <v-slide-y-transition>
    <v-row v-if="usedTranferServers && usedTranferServers.length">
      <v-col cols="12">
        {{ $t ('view.label.transferServer.hint1') }}
      </v-col>
      <v-col cols="12">
        <ul>
          <li v-for="t in usedTranferServers" :key="t">
            {{ t }}
          </li>
        </ul>
      </v-col>
      <v-col cols="12">
        {{ $t ('view.label.transferServer.hint2') }}
      </v-col>
    </v-row>
  </v-slide-y-transition>
</template>

<script>
  import {mapActions} from 'vuex'

  export default {
    props: {
      selectedNameServers: {
        type: Array,
        default: () => ([])
      }
    },

    data () {
      return {
        transferServers: undefined,
        isLoading: false
      }
    },

    computed: {
      usedTranferServers () {
        const ts = []
        if (!this.transferServers) return ts
        this.selectedNameServers.forEach ((ns) => {
          if (!this.transferServers[ns]) return
          this.transferServers[ns].forEach ((t) => {
            if (!ts.includes (t)) ts.push (t)
          })
        })
        return ts
      }
    },

    created () {
      this.loadNameServers ()
    },

    methods: {
      ...mapActions ({
        getTransferServers: 'cache/getTransferServers'
      }),

      async loadNameServers () {
        this.isLoading = true
        this.transferServers = await this.getTransferServers ()
        this.isLoading = false
      }
    }
  }
</script>
