<messages>["./ProviderChain"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->
<template>
  <v-row>
    <v-col
      v-if="!externalControl"
      cols="12"
      class="pa-2">
      <v-switch
        v-model="showProviderChainModel"
        hide-details
        :label="$t ('edit')"
        @click.native="onEdit"/>
    </v-col>
    <v-col
      cols="6"
      class="pa-2">
      <v-text-field
        v-if="showProviderChainModel"
        v-model.trim="chain.type"
        spellcheck="false"
        class="required"
        :label="$t ('type')"
        :error-messages="validationErrors (
          'chain.type',
          { required: 'general.required' })"
        @blur="$v.chain.type.$touch()"/>
    </v-col>
    <v-col
      cols="6"
      class="pa-2">
      <v-text-field
        v-if="showProviderChainModel"
        v-model.trim="chain.spec"
        spellcheck="false"
        :label="$t ('spec')"
        append-icon="undo"
        :hint="$t ('resetHint')"
        persistent-hint
        :error-messages="validationErrors (
          'chain.spec',
          { providerChain: 'general.invalid.providerChain' })"
        @click:append="onReset"
        @blur="$v.chain.spec.$touch()"/>
    </v-col>
  </v-row>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {required} from 'vuelidate/lib/validators'
  import {mapActions} from 'vuex'

  import {providerChain} from '@/app/validators'
  import validationMixins from '@/app/core/mixins/ValidationHelper'

  export default {
    name: 'ProviderChain',

    mixins: [validationMixins],

    props: {
      value: {
        type: Object,
        required: true,
        validator (v) {
          return v.hasOwnProperty ('clientId') &&
            v.hasOwnProperty ('type') &&
            v.hasOwnProperty ('spec')
        }
      },
      edit: {
        type: Boolean,
        default: false
      },
      externalControl: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        showProviderChain: false,
        chain: {
          spec: '',
          type: ''
        },
        defaultChain: ''
      }
    },

    computed: {
      showProviderChainModel: {
        get () {
          if (this.externalControl) {
            return this.edit
          } else {
            return this.showProviderChain
          }
        },

        set (val) {
          this.showProviderChain = val
        }
      }
    },

    watch: {
      showProviderChain () {
        this.$emit ('edit', this.showProviderChain)
      },

      'chain.spec' (newValue) {
        this.value.spec = newValue

        // reload provider chain if null chain is passed as a prop
        if (this.value.spec === null) {
          this.loadProviderChain ()
        }

        this.updateData ()
      },

      'chain.type' (newValue) {
        this.value.type = newValue
        this.updateData ()
      },

      value: {
        handler (newValue) {
          this.chain.spec = newValue.spec
          this.chain.type = newValue.type
        },
        deep: true
      },

      'value.clientId' () {
        this.loadProviderChain ()
      }
    },

    validations: {
      chain: {
        spec: {providerChain},
        type: {required}
      }
    },

    created () {
      this.showProviderChain = this.edit
      this.chain.spec = this.value.spec
      this.chain.type = this.value.type || 'default'
      if (!this.chain.spec) this.loadProviderChain ()
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      /**
       * Check whether the form has any errors, i.e., contains at least one
       * field with invalid data that is flagged "dirty".
       *
       * @return {Boolean}      true if so
       */
      hasErrors () {
        return this.$v.chain.$error
      },

      /**
       * Check whether the form data is valid, i.e., all fields contain valid
       * values.
       *
       * @return {Boolean}      true if so
       */
      isValid () {
        return !this.showProviderChainModel || !this.$v.chain.$invalid
      },

      /**
       * Reset the provider chain to the default chain for the selected client.
       */
      onReset () {
        this.chain.spec = this.defaultChain
        this.chain.type = 'default'
      },

      /**
       * Emit an "edit" event in case the "edit provider chain" switch is
       * toggled.
       */
      onEdit () {
        this.$emit ('edit', this.showProviderChain)
      },

      /**
       * Emit an "input" event informing event listeners of changed data.
       */
      updateData () {
        this.$emit ('input', this.value)
      },

      /**
       * Pre-populate the provider chain field with the default provider chain
       * of the currently acting client.
       */
      loadProviderChain () {
        if (this.value.clientId) {
          return this.fetchData ({
            op: 'client/data/providerChain',
            params: {
              id: this.value.clientId
            },
            cb: data => {
              this.defaultChain = data.providerChain
              this.chain.spec = data.providerChain
            }
          })
        }
      }
    }
  }
</script>
