<messages>["../Zone"]</messages>

<template>
  <base-layout :mw="response ? '3' : '1'">
    <v-col
      v-if="isLoadingClients"
      cols="12">
      <v-progress-linear
        indeterminate
        color="primary"/>
    </v-col>

    <v-col
      v-show="!isLoadingClients"
      :class="{ 'col-12': !response, 'col-8': response }">
      <v-card>
        <form @submit.prevent="onSubmit">
          <v-card-title primary-title>
            <div
              v-t="'createUpdate.title'"
              class="text-h5 mr-1"/>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <client-select
                  v-model="internalClientId"
                  :disabled="!isCreate"
                  @loaded="onClientsLoaded"
                  @loading="isLoadingClients = true"/>
              </v-col>
            </v-row>

            <slot name="content"/>
          </v-card-text>
          <v-card-actions>
            <floating-button
              color="primary"
              :l-offset="2"
              :loading="isLoading">
              {{ $t (`createUpdate.submit.${isCreate ? 'create' : 'update'}`) }}
            </floating-button>
            <v-spacer/>
            <v-btn
              v-if="isCreate"
              v-t="'general.button.reset'"
              text
              @click="doReset"/>
          </v-card-actions>
        </form>
      </v-card>
    </v-col>

    <v-col
      v-if="response"
      cols="4">
      <order-processing-response
        :response="response"
        select-type="zone"/>
    </v-col>
  </base-layout>
</template>

<script>
  import OrderProcessingResponse from '@/app/core/components/RegistryObject/OrderProcessingResponse'
  import FloatingButton from '@/app/core/components/FloatingButton'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import ClientSelect from '@/app/core/components/ClientSelect'

  import {mapGetters} from 'vuex'

  export default {
    name: 'CreateUpdateWrapper',

    components: {
      BaseLayout,
      ClientSelect,
      OrderProcessingResponse,
      FloatingButton
    },

    props: {
      isCreate: Boolean,
      clientId: {
        type: Number,
        default: undefined
      },
      showButtonsAndContent: Boolean,
      response: {
        type: Array,
        default: undefined
      },
      isLoading: Boolean
    },

    data () {
      return {
        internalClientId: 0,
        isLoadingClients: false
      }
    },

    computed: {
      ...mapGetters ({
        actingClientId: 'auth/actingClientId',
        hasAnyOfPermissions: 'auth/hasAnyOfPermissions',
        hasNoneOfPermissions: 'auth/hasNoneOfPermissions'
      }),

      mayManageOnlyOwnObjects () {
        return this.mayManageOwnObjects && this.hasNoneOfPermissions (
          ['ManageAllObjects', 'ManageSubClientObjects'])
      },

      clientName () {
        let clientName = ''

        if (this.clientId) {
          clientName = `CORE-${this.clientId}`

          if (this.availableClients?.length) {
            const client = this.availableClients.find (c =>
              c.value === this.clientId)

            if (client) {
              clientName = client.text
            }
          }
        }

        return clientName
      }
    },

    watch: {
      clientId (newValue) {
        this.internalClientId = newValue || this.preSelectOwnClient ()
      },

      internalClientId (newValue) {
        this.$emit ('clientSelected', newValue)
      }
    },

    created () {
      this.internalClientId = this.isCreate
        ? this.clientId || this.actingClientId
        : this.clientId

      this.$emit ('clientSelected', this.internalClientId)

      if (this.mayManageOnlyOwnObjects) {
        this.$emit ('loaded', 1)
      }
    },

    methods: {
      doReset () {
        this.$emit ('reset')
      },

      onSubmit () {
        this.$emit ('submit')
      },

      onClientsLoaded () {
        this.isLoadingClients = false
        this.$emit ('loaded')
      },

      /**
       * Pre-select the client of the currently acting user (if any).
       */
      preSelectOwnClient () {
        if (!this.internalClientId && this.actingClientId &&
          this.mayManageOwnObjects) {
          this.$emit ('clientSelected', this.actingClientId)
          return this.actingClientId
        }
      },

      mayManageOwnObjects () {
        return this.hasAnyOfPermissions (
          ['ManageOwnObjects', 'ManageAllObjects'])
      }
    }
  }
</script>
