<template>
  <v-autocomplete
    ref="input"
    v-model="selected"
    :loading="isLoading"
    :label="label"
    :items="translatedOptions"
    :error-messages="errorMessages"
    multiple
    @blur="$emit('blur')">
    <template #selection="data">
      <v-chip
        :key="data.item.value"
        close
        outlined
        small
        color="black"
        class="my-1"
        @update:active="data.parent.selectItem (data.item)">
        <span
          class="chipContent"
          :title="data.item">
          {{ data.item.text }}
        </span>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
  import {mapActions} from 'vuex'

  export default {
    props: {
      value: {
        type: Array,
        default: undefined
      },
      label: {
        type: String,
        default: ''
      },
      errorMessages: {
        type: Array,
        default: undefined
      }
    },

    data () {
      return {
        isLoading: false,
        options: null
      }
    },

    computed: {
      selected: {
        get () {
          return this.value
        },
        set (newVal) {
          this.$emit ('input', newVal)
        }
      },

      translatedOptions () {
        if (!this.options) return []
        const ns = this.options.map ((o) => ({
          text: o.name + this.getDescription (o.descriptions),
          value: o.name
        }))

        ns.sort ((n1, n2) => n1.text.localeCompare (n2.text))
        return ns
      }
    },

    created () {
      this.loadNameServers ()
    },

    methods: {
      ...mapActions ({
        getNameServers: 'cache/getNameServers'
      }),

      async loadNameServers () {
        this.isLoading = true
        this.options = await this.getNameServers ()
        this.isLoading = false
      },

      getDescription (d) {
        const desc = d[this.$i18n.locale]

        return desc ? ` (${desc})` : ''
      },
      focus () {
        this.$refs.input.focus ()
      }
    }
  }
</script>
