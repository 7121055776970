<messages>["./Zone"]</messages>

<template>
  <div>
    <base-layout mw3>
      <v-col cols="12">
        <v-alert
          v-model="isSuccessAlertVisible"
          type="success"
          dismissible>
          <i18n path="createUpdate.successUpdate">
            <template #link>
              <router-link
                class="success-alert-link"
                :to="{ name: 'zone.view',
                       params: { name: successData} }"
                v-text="successData"/>
            </template>
          </i18n>
        </v-alert>
      </v-col>
    </base-layout>

    <v-row>
      <v-col cols="12">
        <v-alert
          v-model="error"
          type="error">
          {{ noZone ? $t('createUpdate.noZone') : $t('createUpdate.notFound', {zoneId}) }}
        </v-alert>
      </v-col>
    </v-row>

    <v-alert
      id="invalidDataAlert"
      type="error"
      :value="isInvalidDataAlertVisible">
      {{
        formErrors
          ? $t ('general.invalid.form')
          : $t ('createUpdate.error')
      }}
    </v-alert>

    <zone-create-update
      v-if="!error"
      ref="zoneCreateUpdate"
      :zone-id="zoneId"
      @unsavedChanges="setUnsavedChanges"
      @dataValidityChanged="dataValidityChanged"
      @success="success"
      @failure="failure"
      @notFound="notFound = true"/>
  </div>
</template>

<script>
  import goTo from 'vuetify/lib/services/goto'

  import unsavedChangesHelper from '@/app/core/mixins/UnsavedChangesHelper'
  import BaseLayout from '@/app/core/components/BaseLayout'

  import ZoneCreateUpdate from './components/ZoneCreateUpdate'
  import {mapMutations} from 'vuex'

  export default {
    name: 'ZoneEdit',

    components: {
      BaseLayout,
      ZoneCreateUpdate
    },

    mixins: [unsavedChangesHelper],

    props: {
      zoneId: {type: String, required: true}
    },

    data () {
      return {
        isSuccessAlertVisible: false,
        successData: null,
        isInvalidDataAlertVisible: false,
        formErrors: false,
        noZone: false,
        notFound: false
      }
    },

    computed: {
      error () {
        return this.notFound || this.noZone
      }
    },

    watch: {
      zoneId () {
        this.notFound = false
        this.noZone = false
        this.isSuccessAlertVisible = false
        this.successData = null
        this.formErrors = false

        if (!this.zoneId) {
          this.noZone = true
        }
      }
    },

    created () {
      if (!this.zoneId) {
        this.noZone = true
      }
    },

    methods: {
      ...mapMutations ('notification', ['addEvent']),
      success (e) {
        console.log ('error')
        this.successData = e
        this.addEvent ({
          type: 'success',
          message: this.$t ('createUpdate.successUpdate', {link: e}),
          objects: [{
            name: e,
            link: {
              name: 'zone.view',
              params: {name: e}
            }
          }]
        })
        this.isSuccessAlertVisible = true
      },

      failure () {
        this.successData = null
        this.isSuccessAlertVisible = false
        this.formErrors = false
        this.isInvalidDataAlertVisible = true
        this.scrollToAlert ()
      },

      dataValidityChanged (e) {
        this.formErrors = !e
        this.isInvalidDataAlertVisible = !e
        if (this.isInvalidDataAlertVisible) this.scrollToAlert ()
      },

      scrollToAlert () {
        goTo ('#invalidDataAlert')
      }
    }
  }
</script>
