<messages>["../Zone"]</messages>

<template>
  <create-update-wrapper
    :is-create="isCreate"
    :client-id="zoneObject.zone.clientId"
    :show-buttons="showEditCard"
    :response="response"
    :is-loading="isLoading"
    @loaded="onCreateUpdateComponentLoaded"
    @clientSelected="onClientSelected"
    @submit="onSubmit"
    @reset="onReset">
    <template #content>
      <v-col
        v-if="isLoadingZone"
        cols="12">
        <v-progress-linear
          indeterminate
          color="primary"/>
      </v-col>
      <v-card
        v-else>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="zoneObject.zone.userDefinedId"
                :label="$t('createUpdate.zoneId')"
                :error-messages="validationErrors (
                  'zoneObject.zone.userDefinedId',
                  {required: 'createUpdate.formError.required.userDefinedId'})"
                :disabled="!isCreate"
                @blur="$v.zoneObject.zone.userDefinedId.$touch ()"/>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model.trim="zoneObject.zone.domainName"
                :label="$t('createUpdate.domainName')"
                :error-messages="validationErrors (
                  'zoneObject.zone.domainName',
                  {
                    required: 'createUpdate.formError.required.domainName',
                    idnDomain: 'general.invalid.asciiDomain'
                  })"
                :disabled="!isCreate"
                @blur="$v.zoneObject.zone.domainName.$touch ()"/>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="zoneObject.zone.notifications"
                multiple
                :items="notifications"
                :clearable="true"
                :label="$t('createUpdate.notifications')">
                <template #selection="data">
                  <v-chip
                    :key="data.item"
                    close
                    outlined
                    small
                    class="my-1"
                    color="black"
                    @update:active="data.parent.selectItem (data.item)">
                    <span
                      class="chipContent"
                      :title="data.item">
                      {{ data.item }}
                    </span>
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <name-server-select
                v-model="zoneObject.zone.nameServers"
                :error-messages="validationErrors (
                  'zoneObject.zone.nameServers',
                  {notEmpty: 'createUpdate.formError.required.nameServers'})"
                :label="$t('createUpdate.nameServer')"
                @blur="$v.zoneObject.zone.nameServers.$touch ()"/>
            </v-col>
            <v-col cols="12" class="py-0">
              <provider-chain
                ref="providerchainComponent"
                v-model="chainModel"
                :edit="zoneObject.editProviderChain"
                @edit="zoneObject.editProviderChain = $event"/>
            </v-col>
            <v-col cols="12" class="py-0">
              <radio-group
                v-model="zoneObject.type"
                :option-values="[zoneTypes.LITERAL, zoneTypes.SOURCE]"
                :option-labels="[$t('createUpdate.literal'), $t('createUpdate.source')]"/>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-slide-y-transition mode="out-in">
                <template v-if="zoneObject.type === 'literal'">
                  <v-textarea
                    v-model="resourceRecords"
                    :label="$t('createUpdate.resourceRecords')"
                    :error-messages="validationErrors (
                      'zoneObject.zone.resourceRecords',
                      {isLiteral: 'createUpdate.formError.required.resourceRecords'}
                    )"
                    @blur="$v.zoneObject.zone.resourceRecords.$touch ()"/>
                </template>
                <template v-else>
                  <v-row>
                    <v-col cols="12">
                      <transfer-server-hint :selected-name-servers="zoneObject.zone.nameServers"/>
                    </v-col>
                    <v-col cols="10">
                      <v-text-field
                        v-model.trim="zoneObject.zone.sourceData.address"
                        :label="$t('createUpdate.address')"
                        :error-messages="validationErrors (
                          'zoneObject.zone.sourceData.address',
                          {
                            ip: 'general.invalid.ip',
                            isSource: 'createUpdate.formError.required.srcAddress'
                          })"
                        @blur="$v.zoneObject.zone.sourceData.address.$touch ()"/>
                    </v-col>
                    <v-col cols="2">
                      <v-switch
                        v-model="zoneObject.showTsig"
                        hide-details
                        :label="$t ('createUpdate.editTsig')"/>
                    </v-col>
                    <v-slide-y-transition mode="out-in">
                      <v-col
                        v-if="zoneObject.showTsig"
                        cols="12">
                        <v-row>
                          <v-col cols="12" sm="4">
                            <v-select
                              v-model="zoneObject.zone.sourceData.tsigAlg"
                              :items="tsigAlgs"
                              :label="$t('createUpdate.tsigAlg')"/>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field
                              v-model.trim="zoneObject.zone.sourceData.tsigName"
                              :label="$t('createUpdate.tsigName')"
                              :error-messages="validationErrors (
                                'zoneObject.zone.sourceData.tsigName',
                                {isSource: 'createUpdate.formError.required.tsigName'}
                              )"
                              @blur="$v.zoneObject.zone.sourceData.tsigName.$touch ()"/>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field
                              v-model.trim="zoneObject.zone.sourceData.tsigSecret"
                              :label="$t('createUpdate.tsigSecret')"
                              :error-messages="validationErrors (
                                'zoneObject.zone.sourceData.tsigSecret',
                                {
                                  base64: 'general.invalid.base64',
                                  isSource: 'createUpdate.formError.required.tsigSecret'
                                })"
                              @blur="$v.zoneObject.zone.sourceData.tsigSecret.$touch ()"/>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-slide-y-transition>
                  </v-row>
                </template>
              </v-slide-y-transition>
            </v-col>
            <v-col cols="6">
              <v-switch
                v-model="zoneObject.zone.dnssecEnabled"
                :label="$t('createUpdate.editDnsSec')"/>
            </v-col>
            <v-col cols="6">
              <v-switch
                v-model="zoneObject.zone.autoDomainLinkage"
                :label="$t('createUpdate.editAutoLink')"/>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </template>
  </create-update-wrapper>
</template>

<script>
  import {mapGetters, mapActions, mapMutations} from 'vuex'

  import ProviderChain from '@/app/core/components/RegistryObject/ProviderChain'
  import NameServerSelect from '@/app/core/components/NameServerSelect'
  import RadioGroup from '@/app/core/components/RadioGroup'
  import {toASCII as punycodeToASCII} from 'punycode/punycode.es6'

  import _isEqual from 'lodash/isEqual'

  import _cloneDeep from 'lodash/cloneDeep'

  import {required} from 'vuelidate/lib/validators'

  import {idnDomain, providerChain, ip, base64} from '@/app/validators'

  import CreateUpdateWrapper from './CreateUpdateWrapper'
  import TransferServerHint from './TransferServerHint'

  import {
    getDefaultZoneData,
    DefaultEditProviderChain,
    getDefaultCreateParams,
    DefaultTsigAlgs,
    DefaultNotificationTypes
  } from './constants'

  import validationMixins from '@/app/core/mixins/ValidationHelper'

  const notEmpty = (value) => Array.isArray (value) ? value.length > 0 : true

  const LITERAL = 'literal'
  const SOURCE = 'source'

  export default {
    name: 'ZoneCreateUpdate',

    components: {
      CreateUpdateWrapper,
      ProviderChain,
      RadioGroup,
      NameServerSelect,
      TransferServerHint
    },

    mixins: [validationMixins],

    props: {
      isCreate: {
        type: Boolean,
        default: false
      },
      zoneId: {
        type: String,
        default: undefined
      }
    },

    validations: {
      zoneObject: {
        zone: {
          userDefinedId: {required},
          domainName: {
            required,
            idnDomain: (value) => {
              const idn = punycodeToASCII (value)
              return idnDomain (idn)
            }
          },
          providerChainSpec: {required, providerChain},
          nameServers: {notEmpty},
          sourceData: {
            address: {
              ip,
              isSource (value) {
                if (this.zoneObject.type === 'source') {
                  return required (value)
                }

                return true
              }
            },
            tsigSecret: {
              base64,
              isSource (value) {
                if (this.zoneObject.type === 'source' &&
                  this.zoneObject.showTsig) {
                  return required (value)
                }

                return true
              }
            },
            tsigName: {
              isSource (value) {
                if (this.zoneObject.type === 'source' &&
                  this.zoneObject.showTsig) {
                  return required (value)
                }

                return true
              }
            }
          },
          resourceRecords: {
            isLiteral (value) {
              if (this.zoneObject.type === 'literal') {
                return notEmpty (value)
              }

              return true
            }
          }
        }
      }
    },

    data () {
      return {
        isSuccessAlertVisible: false,
        isLoadingClients: false,
        showEditCard: false,
        response: null,
        zoneObject: {},

        tsigAlgs: [],

        notifications: [],

        isLoading: false,
        isLoadingZone: false,
        defaultZone: null
      }
    },

    computed: {
      ...mapGetters ({
        zoneCreateData: 'create/getZoneCreateData',
        actingClientId: 'auth/actingClientId',
        hasAnyOfPermissions: 'auth/hasAnyOfPermissions'
      }),

      mayManageOwnObjects () {
        return this.hasAnyOfPermissions (
          ['ManageOwnObjects', 'ManageAllObjects'])
      },

      zoneTypes () {
        return {
          LITERAL,
          SOURCE
        }
      },

      chainModel: {
        get () {
          return {
            clientId: this.zoneObject.zone.clientId,
            spec: this.zoneObject.zone.providerChainSpec,
            type: this.zoneObject.zone.providerChainType
          }
        },
        set (newValue) {
          this.zoneObject.zone.providerChainSpec = newValue.spec
          this.zoneObject.zone.providerChainType = newValue.type
        }
      },

      resourceRecords: {
        get () {
          return this.zoneObject.zone.resourceRecords
            ? this.zoneObject.zone.resourceRecords.reduce ((acc, current) => {
              return acc === '' ? current : acc + '\n' + current
            }, '')
            : []
        },
        set (newValue) {
          if (newValue === '') {
            this.zoneObject.zone.resourceRecords = null
          } else {
            this.zoneObject.zone.resourceRecords = newValue.split ('\n')
          }
        }
      }
    },

    watch: {
      'zoneObject.type' (newValue) {
        if (newValue === 'source') {
          this.zoneObject.zone.sourceData = this.zoneObject.savedSourceData

          if (this.zoneObject.zone.resourceRecords) {
            this.zoneObject.savedResourceRecords =
              this.zoneObject.zone.resourceRecords
          }

          this.zoneObject.zone.resourceRecords = []
          this.$v.zoneObject.zone.resourceRecords.$reset ()
        } else {
          this.zoneObject.zone.resourceRecords =
            this.zoneObject.savedResourceRecords

          if (this.zoneObject.zone.sourceData) {
            this.zoneObject.savedSourceData = this.zoneObject.zone.sourceData
          }

          this.zoneObject.zone.sourceData = null
          this.$v.zoneObject.zone.sourceData.$reset ()
        }
      },

      'zoneObject.showTsig' (newValue) {
        if (newValue) {
          this.zoneObject.zone.sourceData = {
            ...this.zoneObject.zone.sourceData,
            ...this.zoneObject.savedTsigData
          }
        } else {
          if (this.zoneObject.zone.sourceData) {
            this.zoneObject.savedTsigData = {
              tsigAlg: this.zoneObject.zone.sourceData.tsigAlg,
              tsigName: this.zoneObject.zone.sourceData.tsigName,
              tsigSecret: this.zoneObject.zone.sourceData.tsigSecret
            }

            this.zoneObject.zone.sourceData = {
              address: this.zoneObject.zone.sourceData.address
            }
          }
        }
      },

      zoneObject: {
        handler (data) {
          this.onChangeZoneData (this.zoneObject)
          if (this.defaultZone && !_isEqual (data.zone, this.defaultZone)) {
            this.$emit ('unsavedChanges', true)
          } else {
            this.$emit ('unsavedChanges', false)
          }
        },
        deep: true
      },

      zoneId () {
        this.zoneIdChangedHandler ()
      }
    },

    created () {
      if (!this.isCreate) {
        this.zoneObject = getDefaultCreateParams ()
        this.loadZone ()
      } else {
        this.zoneObject = this.zoneCreateData
      }

      this.notifications = _cloneDeep (DefaultNotificationTypes)
      this.notifications.sort ((n1, n2) => n1.localeCompare (n2))
      this.tsigAlgs = _cloneDeep (DefaultTsigAlgs)
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      ...mapMutations ({
        setZoneCreateData: 'create/setZoneCreateData',
        showError: 'notification/setError'
      }),

      onCreateUpdateComponentLoaded () {
        if (this.isCreate) {
          this.zoneObject.zone = this.zoneCreateData.zone ||
            getDefaultZoneData ()

          this.zoneObject.editProviderChain =
            this.zoneCreateData.editProviderChain ||
            DefaultEditProviderChain
        }
      },

      /**
       * Set the client ID when a new client has been selected.
       */
      onClientSelected (clientId) {
        if (clientId !== this.zoneObject.zone.clientId) {
          this.zoneObject.zone.clientId = clientId
        }

        this.showEditCard = true
      },

      onChangeZoneData (data) {
        // store zone data to vuex store in zone creation case (to be able
        // to switch to another page and then return to zone creation)
        if (this.isCreate) {
          this.setZoneCreateData (data)
        }
      },

      onClientsLoaded () {
        this.showEditCard = true
        this.isLoadingClients = false
      },

      onSubmit () {
        // TODO, FIXME: this is a workaround for https://github.com/vuetifyjs/vuetify/issues/5203
        // (if submitted directly from combobox, then the combobox value is not updated yet, so wait...)
        // This workaround can be reverted as soon as the issue is resolved and the Vuetify library is updated
        setTimeout (() => {
          if (!this.$v.$invalid) {
            this.$emit ('dataValidityChanged', true)

            const submitObject = _cloneDeep (this.zoneObject.zone)

            if (!this.zoneObject.editProviderChain) {
              delete submitObject.providerChainSpec
              delete submitObject.providerChainType
            }

            if (this.isCreate) {
              this.createZone (submitObject)
            } else {
              this.updateZone (submitObject)
            }
          } else {
            this.$emit ('dataValidityChanged', false)
            this.$v.$touch ()
          }
        })
      },

      createZone (data) {
        this.isLoading = true
        this.fetchData ({
          op: 'zone/create',
          params: {data: data},
          cb: data => {
            if (Array.isArray (data.errors) && data.errors.length > 0) {
              this.response = data.errors
              this.$emit ('failure')
            } else {
              this.response = null

              if (this.isCreate) {
                this.onReset ()
              }

              this.$emit ('success', data.handle)
            }
          },
          cbError: data => {
            this.$emit ('failure')
            this.response = data.errors
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      },

      updateZone (data) {
        this.isLoading = true
        this.fetchData ({
          op: 'zone/update',
          params: {data: data},
          cb: data => {
            if (Array.isArray (data.errors) && data.errors.length > 0) {
              this.response = data.errors
              this.$emit ('failure')
            } else {
              this.response = null

              if (this.isCreate) {
                this.onReset ()
              }

              this.$emit ('unsavedChanges', false)
              this.$emit ('success', data.handle)
            }
          },
          cbError: data => {
            this.$emit ('failure')
            this.response = data.errors
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      },

      loadZone () {
        this.isLoadingZone = true

        const request = {
          op: 'zone/load',
          params: {name: this.zoneId},
          cb: data => {
            if (data.zoneData.sourceData) {
              this.zoneObject.type = 'source'

              if (data.zoneData.sourceData.tsigName) {
                this.zoneObject.savedTsigData = {
                  tsigAlg: data.zoneData.sourceData.tsigAlg,
                  tsigName: data.zoneData.sourceData.tsigName,
                  tsigSecret: data.zoneData.sourceData.tsigSecret
                }

                this.zoneObject.showTsig = true
              }
              this.zoneObject.savedSourceData = data.zoneData.sourceData
            }

            this.zoneObject.zone = data.zoneData
          },
          cbError: () => {
            this.$emit ('notFound', this.zoneId)
          },
          cbFinal: () => {
            this.isLoadingZone = false
            this.defaultZone = _cloneDeep ({
              ...this.zoneObject.zone,
              ...this.zoneObject.zone.resourceRecords
                ? {}
                : {resourceRecords: []}
            })
          }
        }

        this.fetchData (request)
      },

      onReset () {
        this.$emit ('dataValidityChanged', true)
        this.response = null

        const clientId = this.zoneObject.zone.clientId
        this.$refs.providerchainComponent.onReset ()
        // const chainType = this.zoneObject.zone.providerChainType
        const chainSpec = this.zoneObject.zone.providerChainSpec

        this.zoneObject = getDefaultCreateParams ()
        this.zoneObject.zone.clientId = clientId
        // this.zoneObject.zone.providerChainType = chainType
        this.zoneObject.zone.providerChainSpec = chainSpec
        this.$v.$reset ()
      },

      zoneIdChangedHandler () {
        this.zoneObject = this.zoneCreateData
        if (!this.isCreate) {
          this.loadZone ()
        }
      }
    }
  }
</script>
